// @flow
import { type JSSThemeDefaultAuthFormType } from '../JSSThemeDefaultAuthForm';

export const JSSThemeAuthForm61200: JSSThemeDefaultAuthFormType = (
  themeVars
) => {
  const {
    margins,
    borderRadius,
    colors: {
      colorTypes: { text1, background1 },
    },
  } = themeVars;
  return {
    LoginFormDesktop: {
      popUpWrapper: {
        background: background1,
        boxShadow: '0 5px 20px rgba(0, 0, 0, 0.8)',
        '&:before': {
          background: background1,
        },
      },
    },
    AuthFormDesktop: {
      depositWrap: {
        padding: [margins.xs, margins.md],
        background: text1,
        borderRadius: borderRadius.md,
        marginRight: margins.xs,
      },
    },
  };
};
