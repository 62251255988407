// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme61200,
  JSSThemeVariables61200,
  getJSSThemePatterns61200,
} from './JSSTheme61200';
import { GeoblockPage61200 } from './GeoblockPage61200';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullDesktop61200')).FullDesktop61200,
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullMobile61200')).FullMobile61200,
  }),
  JSSTheme: JSSTheme61200,
  JSSThemeVariables: JSSThemeVariables61200,
  JSSThemePatterns: getJSSThemePatterns61200,
  GeoblockPage: GeoblockPage61200,
});
