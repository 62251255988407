// import { type JSSThemeDefaultFooterType } from '../JSSThemeDefaultFooter';
// @flow
import { type JSSThemeDefaultFooterAllType } from '../JSSThemeDefaultFooterAll';

export const JSSThemeFooterAll61200: JSSThemeDefaultFooterAllType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white, background1 },
    },
  } = themeVars;
  return {
    footerWrap: {
      background: background1,
      color: white,
    },
    socialHide: {
      display: 'none',
    },
    socialWrap: {
      display: 'flex',
    },
    menuWrapper: {
      width: '80%',
      margin: '0 auto',
    },
    licenseLink: {
      paddingRight: 0,
    },
    newStyle: {
      alignItems: 'center',
      '@media screen and (max-width: 1300px)': {
        flexWrap: 'wrap',
      },
    },
    logoSvg: {
      width: 80,
      maxHeight: 40,
    },
    menu: {
      '@media screen and (max-width: 683px)': {
        '&:nth-child(1)': {
          display: 'none',
        },
        '&:nth-child(2)': {
          display: 'none',
        },
      },
    },
  };
};
