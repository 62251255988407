// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeIPools61200 = (themeVars: typeof JSSThemeVariables) => {
  const {
    margins,
    colors: {
      colorTypes: {
        error,
        primary,
        background3,
        text4,
        white,
        text2,
        text3,
        text5,
        background6,
        accent,
        black,
        text1,
        background7,
        success,
      },
    },
  } = themeVars;
  return {
    IPoolsBanners: {
      banner: {
        textShadow: '',
      },
    },
    IPools: {
      upcomingPools: {
        background: black,
      },
      root: {
        background: 'transparent',
      },
      howToLinkManual: {
        backgroundColor: background6,
        background:
          'url(https://cdn.rojabet.com/images/ipools-src/bg-info-top.svg) no-repeat right bottom',
      },
      howToLinkVideo: {
        backgroundColor: background6,
        background:
          'url(https://cdn.rojabet.com/images/ipools-src/bg-info-bottom.svg) no-repeat right top',
      },
      event: {
        backgroundColor: background6,
        color: 'white !important',
        background:
          'url(https://cdn.rojabet.com/images/ipools-src/trophy-bg.svg) no-repeat right bottom',
      },
      topBarIconLive: {
        color: primary,
      },
      eventLive: {
        color: primary,
      },
      winBigJackpotLabel: {
        color: primary,
      },
      enteredLabel: {
        color: primary,
        boxShadow: `0px 0px 0px 1.5px ${primary}`,
        textAlign: 'end',
        minWidth: 'auto',
      },
      eventInfoLabel: {
        '&.isBigJackpot': {
          color: primary,
        },
      },
    },
    IPoolsSettled: {
      rootSettled: {
        background: 'transparent',
        color: text2,
      },
      event: {
        '@media (pointer: coarse)': {
          backgroundColor: background6,
        },
        backgroundColor: background6,
        color: 'white !important',
        background:
          'url(https://cdn.rojabet.com/images/ipools-src/trophy-bg-lite.svg) no-repeat right bottom',
      },
      eventSelected: {
        boxShadow: `inset 0px 0px 0px 3px #84c684`,
      },
      winBigJackpotLabel: {
        color: primary,
      },
      eventName: {
        opacity: 0.5,
      },
      eventInfoLabel: {
        '&.isBigJackpot': {
          color: primary,
        },
      },
      settledRoot: {
        background: 'transparent',
        color: text2,
        '&:hover': {
          color: 'rgba(34,34,34,0.8)',
        },
      },
      settledToggler: {
        color: success,
        '&:hover': {
          color: primary,
        },
      },
    },
    Entries: {
      seatsTitle: {
        color: text4,
        '@media (pointer: coarse)': {
          color: text4,
        },
      },
      newSeat: {
        color: primary,
        border: `5px solid ${primary}`,
        '&.active': {
          '&:hover': {
            border: `5px solid ${white}`,
            color: primary,
          },
          color: white,
          border: `5px solid ${white}`,
          background: background7,
        },
        '&:disabled': {
          cursor: 'not-allowed',
          color: text3,
          border: `5px solid ${text3}`,
          opacity: 0.5,
        },
      },
      seat: {
        background: white,
        color: black,
        '&:hover': {
          background: primary,
          color: black,
        },
        '&.active': {
          background: primary,
          color: black,
        },
        '@media (pointer: coarse)': {
          background: white,
        },
      },
    },
    EventHeader: {
      color: white,
      prizeFundRoot: {},
      wrapBars: {},
      subHeading: {
        color: white,
        '@media (pointer: coarse)': {
          color: white,
        },
      },
      placesMobile: {
        background: white,
      },
      barContainer: {},
      places: {},
    },
    QuestionHeader: {
      live: {
        questionHeader: {
          color: white,
        },
        questionColumnsLiveOrEnded: {
          '@media (pointer: coarse)': {
            marginRight: '39px !important',
          },
        },
        questionColumn: {
          width: 92,
        },
      },
      settled: {
        questionColumn: {
          width: 92,
        },
      },
    },
    Question: {
      live: {
        question: {
          color: white,
        },
        questionBanker: {
          background: '#75757530',
        },
        questionTime: {
          '@media screen and (min-width: 1440px)': {
            display: 'block',
          },
        },
        bankerLabel: {
          color: primary,
          '@media screen and (min-width: 1440px)': {
            display: 'inline-block',
          },
        },
        choice: {
          background: background3,
          '&.active': {
            background: `${primary} !important`,
            transition: 'all 0.4s',
            color: black,
          },
          '&:hover': {
            color: black,
            background: background3,
          },
        },
        choiceHoverable: {
          '@media not all and (pointer: coarse)': {
            '&:hover': {
              color: black,
              background: primary,
            },
          },
        },
        column: {
          color: white,
          display: 'none',
        },
        x2Button: {
          height: 55,
          background: white,
          color: black,
          '&.active': {
            color: black,
            background: `${primary} !important`,
          },
          '&:hover': {
            color: black,
            background: primary,
          },
          '@media screen and (min-width: 1440px)': {
            height: 38,
          },
          '@media (pointer: coarse)': {
            color: black,
            background: white,
            '&:hover': {
              background: white,
              color: black,
            },
          },
        },
        resultLabelCheck: {
          color: primary,
        },
        mixedAnswer: {
          '@media screen and (max-width: 1020px)': {
            width: '80%',
          },
        },
        selectInput: {
          '& select': {
            background: background6,
            color: white,
          },
          '&.active': {
            '& select': {
              color: black,
              background: primary,
            },
          },
        },
        dropdownIcon: {
          color: white,
        },
        infoDescriptionWrapperMultiChoice: {
          '&:before': {
            backgroundColor: text1,
          },
        },
        infoDescriptionMultiChoice: {
          color: white,
          background: text1,
        },
        rangeInput: {
          '&.active': {
            '& input[type=range]::-webkit-slider-thumb': {
              background: success,
            },
          },
          '& input[type=range]::-webkit-slider-runnable-track': {
            background: background6,
          },
        },
        rangeInputValue: {
          '&.active': {
            color: success,
          },
        },
      },
      settled: {
        questionTime: {
          display: 'flex',
          marginTop: 2,
          '@media screen and (min-width: 1440px)': {
            display: 'block',
          },
        },
        bankerLabel: {
          marginLeft: margins.xs,
          display: 'inline-block',
          '@media screen and (min-width: 1440px)': {
            display: 'inline-block',
          },
        },
        questionLeagueMobile: {
          marginBottom: 0,
        },
        choice: {
          background: background6,
          color: white,
          '&.active': {
            color: black,
            background: primary,
            '&:hover': {
              background: primary,
            },
          },
        },
        column: {
          display: 'none',
          color: white,
        },
        resultLabelCheck: {
          color: primary,
        },
        mixedAnswer: {
          '@media screen and (max-width: 1020px)': {
            width: '80%',
          },
        },
        dropdownIcon: {
          color: white,
        },
        selectInput: {
          '& select': {
            background: primary,
            color: white,
          },
          '&.active': {
            '& select': {
              color: background7,
              background: primary,
              opacity: 0.4,
            },
          },
        },
        infoDescriptionWrapperMultiChoice: {
          '&:before': {
            backgroundColor: text1,
          },
        },
        infoDescriptionMultiChoice: {
          color: white,
          background: text1,
        },
        rangeInput: {
          '& input[type=range]::-webkit-slider-thumb': {
            background: primary,
          },
        },
        rangeInputValue: {
          color: primary,
        },
      },
    },
    IPoolsEvent: {
      questionsWrapper: {
        background: black,
      },
      rootEventHeaderPic: {
        background:
          'url(https://cdn.processingservices.biz/casino/upload_4e6c38c9c8d608755f357ab4241965ea.png) no-repeat 100% 210px',
        '@media screen and (max-width: 1194px)': {
          background:
            'url(https://cdn.processingservices.biz/casino/upload_4e6c38c9c8d608755f357ab4241965ea.png) no-repeat 100% 260px',
          backgroundSize: 315,
        },
        '@media screen and (min-width: 1440px)': {
          background:
            'url(https://cdn.processingservices.biz/casino/upload_4e6c38c9c8d608755f357ab4241965ea.png) no-repeat 100% 180px',
        },
      },
      root: {
        '@media (pointer: coarse)': {
          background: 'transparent',
        },
      },
      showAllToggler: {
        color: text4,
      },
      backLink: {
        color: text2,
      },
      rootEvent: {
        '@media (pointer: coarse)': {
          background: 'transparent',
        },
      },
      EventHeader: {
        color: white,
        '@media (pointer: coarse)': {
          color: white,
        },
      },
      questions: {
        background: white,
        color: text4,
      },
    },
    IPoolsSettledEvent: {
      questionsWrapper: {
        background: black,
      },
      backLink: {
        color: text4,
      },
      rootEvent: {
        '@media (pointer: coarse)': {
          background: 'transparent',
        },
      },
      rootEventHeaderSettled: {
        opacity: 1,
      },
      rootEventHeaderPic: {
        background:
          'url(https://cdn.processingservices.biz/casino/upload_4e6c38c9c8d608755f357ab4241965ea.png) no-repeat 100% 200px',
        '@media screen and (max-width: 1178px)': {
          background:
            'url(https://cdn.processingservices.biz/casino/upload_4e6c38c9c8d608755f357ab4241965ea.png) no-repeat 100% 260px',
          backgroundSize: 315,
        },
        '@media screen and (min-width: 1440px)': {
          background:
            'url(https://cdn.processingservices.biz/casino/upload_4e6c38c9c8d608755f357ab4241965ea.png) no-repeat 100% 170px',
          backgroundSize: 315,
        },
      },
      settledPoolMark: {
        color: primary,
      },
      questionLeftCol: {
        '@media (pointer: coarse)': {
          background: black,
        },
      },
      questions: {
        background: white,
        color: text4,
      },
    },
    IPoolsSubmit: {
      rootMobile: {
        background: primary,
      },
      rootDesktop: {
        background: text1,
        color: white,
      },
      totalStake: {
        color: black,
      },
      error: {
        color: error,
        '@media (pointer: coarse)': {
          color: accent,
        },
      },
    },
    IPoolsSubmitChoice: {
      totalStake: {
        marginBottom: margins.md,
      },
      root: {
        color: black,
      },
    },
    IPoolsMaxSeatsAttention: {
      rootMobile: {
        color: black,
      },
      rootDesktop: {
        background: primary,
        color: black,
      },
    },
    IPoolsLeaderboardStats: {
      root: {
        color: black,
      },
    },
    IPoolsLeaderboard: {
      leaderboardTableHeading: {
        color: white,
      },
      root: {
        background: background7,
        color: text5,
        '@media (pointer: coarse)': {
          background: black,
          borderRadius: 0,
        },
      },
      row: {
        '&:first-child': {
          color: '#F8C300',
        },
      },
      playerScore: {
        color: '#F8C300',
      },
      leaderboardHeading: {
        color: white,
      },
    },
    IPoolsBars: {
      title: {
        color: white,
        '@media (pointer: coarse)': {
          color: white,
        },
      },
      link: {
        color: text4,
        '&:hover': {
          color: white,
        },
        '@media (pointer: coarse)': {
          color: text4,
        },
      },
    },
  };
};
