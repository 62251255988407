// @flow
export const JSSThemeXtremepush61200 = () => {
  return {
    xtremepushBadge: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: 0,
      right: -5,
      height: 20,
      minWidth: 20,
      paddingLeft: 5,
      paddingRight: 5,
      fontSize: 14,
      borderRadius: 10,
      color: 'black',
      border: '1px solid black',
      backgroundColor: 'white',
      pointerEvents: 'none',
    },
  };
};
