// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  type ColorTypes,
  getColorCombinations,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { applyOnlyDesktop, getButtonColors } from '../jss-helpers';
import { JSSThemeBetslip as betslip } from './JSSThemeBetslip61200';
import { JSSThemeHeaderMenu61200 } from './JSSThemeHeaderMenu61200';
import { JSSThemeMyBets61200 } from './JSSThemeMyBets61200';
import { JSSThemeBannerItems61200 } from './JSSThemeBannerItems61200';
import { JSSThemeSportsMenu61200 } from './JSSThemeSportsMenu61200';
import { JSSThemeForm61200 } from './JSSThemeForm61200';
import { JSSThemeTooltip61200 } from './JSSThemeTooltip61200';
import { JSSThemeBonuses61200 } from './JSSThemeBonuses61200';
import { JSSThemeHeaderMobile61200 } from './JSSThemeHeaderMobile61200';
import { JSSThemeSearch61200 } from './JSSThemeSearch61200';
import { JSSThemeEventSlider61200 } from './JSSThemeEventSlider61200';
import { JSSThemeOutcomes61200 } from './JSSThemeOutcomes61200';
import { JSSThemeCoupons61200 } from './JSSThemeCoupons61200';
import { JSSThemeHeader61200 } from './JSSThemeHeader61200';
import { JSSThemeBalance61200 } from './JSSThemeBalance61200';
import { JSSThemeAuthForm61200 } from './JSSThemeAuthForm61200';
import { JSSThemePopularLeagues61200 } from './JSSThemePopularLeagues61200';
import { JSSThemeAccountSection61200 } from './JSSThemeAccountSection61200';
import { JSSTheme61200Resets } from './JSSTheme61200Resets';
import { JSSThemeLive61200 } from './JSSThemeLive61200';
import { JSSThemeCasino61200 } from './JSSThemeCasino61200';
import { JSSThemePromotions61200 } from './JSSThemePromotions61200';
import { JSSThemeSitemap61200 } from './JSSThemeSitemap61200';
import { JSSThemeFooterAll61200 } from './JSSThemeFooterAll61200';
import { JSSThemeDropdownMenu61200 } from './JSSThemeDropdownMenu61200';
import { JSSThemeAffiliates61200 } from './JSSThemeAffiliates61200';
import { JSSThemeIPools61200 } from './JSSThemeIPools61200';
import { JSSThemeXtremepush61200 } from './JSSThemeXtremepush61200';

export const colorTypes61200: ColorTypes = {
  ...colorTypes,
  primary: '#52EF52',
  text1: '#494949',
  text2: '#bfbfbf',
  text3: '#203552',
  text4: 'rgba(255, 255, 255, 0.5)',
  text5: '#03db01',
  background1: '#141414',
  background2: '#252525',
  background3: '#686868',
  background4: '#999',
  background5: '#0f3b86',
  background6: '#3e3e3e', //new color
  background7: '#252525',
  shadow: 'rgba(0, 0, 0, 0.4)',
  accent: '#4e8235',
  success: '#21a22d',
  error: '#ff3434',
  error2: '#ff3434',
  error3: '#ff3434',
  warning: 'orange',
  inform: '#830202',
  neutral1: '#2B456B',
  neutral2: '#5c0101',
  neutral3: '#3E3E3E',
  neutral4: '#1f3d63', //available for a new color
  border1: '#545e66',
  border2: '#0d1f2d',
};

const pick = ({
  primaryWhite,
  whitePrimary,
  text2Primary,
  whiteBg1,
  whiteBg2,
  whiteBg3,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral2,
  text2Neutral1,
  text2Bg2,
  whiteError,
  whiteNeutral1,
  text1Bg4,
  primaryBg1,
  text3White,
  text2Bg3,
  whiteText3,
}) => ({
  primaryWhite,
  whitePrimary,
  text2Primary,
  whiteBg1,
  whiteBg2,
  whiteBg3,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral2,
  text2Neutral1,
  text2Bg2,
  whiteError,
  whiteNeutral1,
  text1Bg4,
  primaryBg1,
  text3White,
  text2Bg3,
  whiteText3,
});

export const colorCombinations61200 = getColorCombinations(
  colorTypes61200,
  pick
);

export const JSSThemeVariables61200: JSSThemeVariablesShapeType = {
  colors: {
    colorTypes: colorTypes61200,
    colorCombinations: colorCombinations61200,
  },
  fonts: {
    default: {
      name: 'osg-mont',
      fontFamily: 'osg-mont, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'mont/mont-book-webfont',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'mont/mont-bold-webfont',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
      ],
    },
  },
};

export const JSSTheme61200: JSSThemeShapeType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { whiteBg1, text4 },
      colorTypes: {
        background1,
        background3,
        background7,
        primary,
        text2,
        text1,
        black,
        white,
        neutral3,
        accent,
      },
    },
    fontSizes,
    margins,
    borderRadius,
  } = themeVars;
  return {
    Heading: {
      common: {
        color: text4,
        padding: [margins.md, margins.xm, '0'],
      },
    },
    Geoblock: {
      logoGeoblock: {
        background: background1,
      },
      textGeoblock: {
        color: white,
      },
      rootGeoblock: {
        background: background7,
      },
    },
    CookiesBanner: {
      CookiesBannerButtonAccept: {
        color: black,
        '&:hover': {
          color: black,
        },
      },
      CookiesBannerButtonDecline: {
        color: black,
        '&:hover': {
          color: black,
        },
      },
    },
    CookiesBannerMobile: {
      CookiesBannerButtonAccept: {
        color: black,
        '&:hover': {
          color: black,
        },
      },
      CookiesBannerButtonDecline: {
        color: black,
        '&:hover': {
          color: black,
        },
      },
    },
    mybets: JSSThemeMyBets61200(themeVars),
    header: JSSThemeHeader61200(themeVars),
    headerMenu: JSSThemeHeaderMenu61200(themeVars),
    Button: {
      root: {
        lineHeight: '26px',
      },
      default: {
        color: black,
        background: primary,
        '&:hover': {
          background: accent,
          color: black,
        },
      },
      primary: {
        color: white,
        background: neutral3,
        '&:hover': {
          background: text1,
        },
        '&:disabled:hover, :disabled:active': {
          background: '#3e3e3e !important',
        },
      },
      secondary: {
        outline: '2px solid #52EF52',
        outlineOffset: '-2px',
        color: primary,
        background: background1,
        '&:hover': {
          color: primary,
          background: background1,
          boxShadow: '0 0 5px 1px rgba(82, 239, 82, 1)',
        },
        '&:focus, &:active': {
          outline: '2px solid #52EF52 !important',
          outlineOffset: '-2px',
          color: primary,
          background: background1,
        },
      },
      accent: {
        outline: '2px solid #52EF52',
        outlineOffset: '-2px',
        color: primary,
        background: background1,
        '&:hover': {
          color: primary,
          background: background1,
          boxShadow: '0 0 5px 1px rgba(82, 239, 82, 1)',
        },
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
      linkSecondary: {
        color: text2,
        ...applyOnlyDesktop({
          '&:hover': {
            color: primary,
          },
        }),
      },
      linkAccent: {
        color: primary,
        ...applyOnlyDesktop({
          '&:hover': {
            color: primary,
            opacity: 0.7,
          },
        }),
      },
    },
    betslip: betslip(themeVars),
    LiveCalendar: {
      h2: {
        ...whiteBg1,
        fontWeight: 'bold',
        padding: [0, margins.md],
      },
      dateTitle: {
        background: background3,
        borderBottom: `1px solid ${background1}`,
        padding: [margins.sm, margins.md],
      },
      dateEvent: {
        background: background1,
        borderBottom: `1px solid ${background3}`,
        padding: [margins.sm, margins.md],
        '&:hover': {
          background: background3,
        },
      },
    },
    IconCircle: {
      root: {
        background: primary,
      },
    },
    NoBetsMessage: {
      root: {
        background: background1,
      },
    },
    // Geoblock: {
    //   logoGeoblock: {
    //     // background: text3,
    //   },
    //   rootGeoblock: {
    //     // background: background5,
    //   },
    // },
    BetslipMyBetsPanelTitle: {
      heading: {
        boxShadow: `inset 0px -1px ${black}`,
        background: 'transparent',
        color: white,
      },
      title: {
        lineHeight: `${margins.xl}px`,
        fontWeight: 'normal',
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakeTitle: {
        fontWeight: 'normal',
        marginBottom: margins.md,
        marginTop: 0,
        fontSize: fontSizes.sm,
      },
      money: {
        fontSize: fontSizes.md,
        color: white,
      },
      stakePotentialWrapper: {
        marginTop: margins.xs,
        marginBottom: margins.sm,
        background: neutral3,
        padding: [margins.xs, margins.md],
        borderTop: `none`,
        borderRadius: borderRadius.md,
      },
      stakePotential: {
        color: text2,
      },
    },
    BetslipMyBetsPanelUI: {
      panel: {
        ...whiteBg1,
        fontSize: fontSizes.sm,
        margin: 0,
      },
    },
    banners: JSSThemeBannerItems61200(themeVars),
    sportsMenu: JSSThemeSportsMenu61200(themeVars),
    form: JSSThemeForm61200(themeVars),
    tooltip: JSSThemeTooltip61200(themeVars),
    bonuses: JSSThemeBonuses61200(themeVars),
    headerMobile: JSSThemeHeaderMobile61200(themeVars),
    search: JSSThemeSearch61200(themeVars),
    eventSlider: JSSThemeEventSlider61200(themeVars),
    outcomes: JSSThemeOutcomes61200(themeVars),
    coupons: JSSThemeCoupons61200(themeVars),
    balance: JSSThemeBalance61200(themeVars),
    live: JSSThemeLive61200(themeVars),
    authForm: JSSThemeAuthForm61200(themeVars),
    popularLeagues: JSSThemePopularLeagues61200(themeVars),
    accountSection: JSSThemeAccountSection61200(themeVars),
    promotions: JSSThemePromotions61200(themeVars),
    sitemap: JSSThemeSitemap61200(themeVars),
    resets: JSSTheme61200Resets(themeVars),
    casino: JSSThemeCasino61200(themeVars),
    footerAll: JSSThemeFooterAll61200(themeVars),
    affiliates: JSSThemeAffiliates61200(themeVars),
    dropdownMenu: JSSThemeDropdownMenu61200(themeVars),
    ipools: JSSThemeIPools61200(themeVars),
    xtremepush: JSSThemeXtremepush61200(),
  };
};

export const getJSSThemePatterns61200: GetJSSThemePatternsType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { black, primary, text5 },
      colorCombinations: { text2Bg2, whitePrimary },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: {
        color: black,
        background: primary,
        '&:hover': {
          color: black,
          background: text5,
        },
      },
      secondary: getButtonColors(text2Bg2),
      accent: getButtonColors(whitePrimary),
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};
