// @flow
import { type JSSThemeDefaultPopularLeaguesType } from '../JSSThemeDefaultPopularLeagues';

export const JSSThemePopularLeagues61200: JSSThemeDefaultPopularLeaguesType = themeVars => {
  const {
    colors: {
      colorTypes: { background1, primary },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    PopularLeagues: {
      root: {
        background: background1,
        '@media (pointer: coarse)': {
          background: background1,
        },
      },
      header: {
        color: primary,
        fontSize: fontSizes.xxl,
      },
      link: {
        '&:hover': {
          // костыль, специально для популярных лиг (причина: отсутствие каскада в jss)
          background: '#9700ba',
          filter: 'invert(1)',
        },
        '@media (pointer: coarse)': {
          fontSize: fontSizes.xxl,
          padding: [margins.xs, margins.xm],
        },
      },
    },
  };
};
