// @flow
import { type JSSThemeDefaultOutcomesType } from '../JSSThemeDefaultOutcomes';

export const JSSThemeOutcomes61200: JSSThemeDefaultOutcomesType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { neutral1, text1, white, background6, primary, black },
    },
  } = themeVars;
  return {
    MobileOutcome: {
      root: {
        background: background6,
        '&.active': {
          color: black,
          background: primary,
        },
      },
      wideRoot: {
        background: neutral1,
        border: `1px solid ${text1}`,
      },
      value1x2: {
        '&.active': {
          color: black,
        },
      },
      priceValue: {
        color: white,
        '&.active': {
          color: black,
        },
      },
    },
    Outcome: {
      root: {
        color: white,
        background: background6,
        height: '28px',
        lineHeight: '28px',
        border: `1px solid rgba(255, 255, 255, 0.1)`,
        '&.active': {
          color: black,
          background: primary,
        },
        '@media not all and (pointer: coarse)': {
          '&:hover': {
            color: black,
            background: primary,
          },
        },
      },
    },
  };
};
