// @flow
import { type JSSThemeDefaultFormType } from '../JSSThemeDefaultForm';

export const JSSThemeForm61200: JSSThemeDefaultFormType = (themeVars) => {
  const {
    colors: {
      colorTypes: { text2, primary, background1, background3, background7 },
    },
    borderRadius,
    margins,
    fontSizes,
  } = themeVars;
  return {
    Input: {
      root: {
        borderColor: background1,
        borderRadius: borderRadius.md,
        color: text2,
        background: background3,
        padding: [9, 10, 9, 14],
        lineHeight: 'auto',
        height: 'auto',
        transition: '0.3s ease-in',
        '&::placeholder': {
          color: text2,
          opacity: 0.8,
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px ${background3} inset !important`,
          borderColor: background1,
          '-webkit-text-fill-color': `${text2} !important`,
        },
      },
      betslipInput: {
        fontSize: fontSizes.lg,
        fontWeight: 'bold',
        color: primary,
        height: '30px',
        lineHeight: '30px',
        border: 'none',
        borderRadius: borderRadius.md,
        background: background7,
        '&::placeholder': {
          color: primary,
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px ${background7} inset !important`,
          borderColor: `${background7} !important`,
          '-webkit-text-fill-color': `${primary} !important`,
        },
      },
    },
    Message: {
      messageError: {
        padding: '3px 0',
        lineHeight: 1.1,
        '@media screen and (max-width: 550px)': {
          fontSize: 9,
          lineHeight: 1
        },
      },
    },
    Field: {
      root: {
        marginBottom: margins.xm * 2.4,
        '@media (pointer: coarse)': {
          marginBottom: margins.xm * 2,
        },
      },
      fieldLabel: {
        fontSize: fontSizes.lg,
        padding: [0, 0, margins.xs],
        fontWeight: 'bold',
      },
      checkboxField: {
        margin: ['30px', 0, 0],
        position: 'relative',
      },
    },
  };
};
