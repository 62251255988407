// @flow
import { type JSSThemeDefaultHeaderMenuType } from '../JSSThemeDefaultHeaderMenu';

export const JSSThemeHeaderMenu61200: JSSThemeDefaultHeaderMenuType = (
  themeVars
) => {
  const {
    margins,
    colors: {
      colorTypes: { white, primary, background1 },
    },
  } = themeVars;
  const menuItemAfterElement = {
    content: '""',
    position: 'absolute',
    textAlign: 'center',
    bottom: '0',
    left: '6%',
    width: '90%',
    borderBottom: `3px solid ${primary}`,
  };
  return {
    HeaderMenuDesktop: {
      menuWrapper: {
        background: 'none',
        textTransform: 'uppercase',
      },
      menuItemWrapper: {
        borderRight: 'none',
      },
      menuItem: {
        color: white,
        padding: [0, margins.lg],
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        height: 75,
        '&:hover, &:focus': {
          color: primary,
          '&:after': menuItemAfterElement,
        },
        '@media screen and (max-width: 1700px)': {
          padding: '0px 7px',
          fontSize: 12,
        },
        '@media screen and (max-width: 1320px)': {
          padding: '0px 5px',
          fontSize: 11,
        },
        '@media screen and (max-width: 1150px)': {
          fontSize: 10,
        },
        '@media screen and (max-width: 1080px)': {
          fontSize: 9,
        },
      },
      menuItemActive: {
        color: primary,
        '&:after': menuItemAfterElement,
      },
    },
    HeaderMenuMobile: {
      mobileMenu: {
        background: background1,
        flexWrap: 'wrap',
      },
      menuItem: {
        borderBottom: `3px solid ${background1}`,
        '&:hover, &:focus': {
          color: white,
        },
        '&.active': {
          color: primary,
        },
      },
      // menuItemActive: {
      //   background: text3,
      //   borderBottom: `3px solid ${primary}`,
      // },
    },
  };
};
