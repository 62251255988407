// @flow
import { type JSSThemeDefaultAffiliatesType } from '../JSSThemeDefaultAffiliates';

export const JSSThemeAffiliates61200: JSSThemeDefaultAffiliatesType = themeVars => {
  const {
    margins,
    colors: {
      colorTypes: { background1, background3, primary, text5, white },
    },
  } = themeVars;

  return {
    text: {
      margin: margins.md,
    },
    affiliatesHome: {
      affiliateRoot: {
        background: background1,
      },
      stepNumber: {
        background: background3,
      },
      step: {
        boxShadow: '0px 8px 21px 14px rgb(17 40 64 / 10%)',
      },
      topBannerHeader: {
        fontSize: '52px',
        lineHeight: '62px',
      },
      topBannerButton: {
        boxShadow: 'none',
        background: primary,
        backgroundColor: primary,
        '&:hover': {
          boxShadow: 'none',
          background: text5,
          backgroundColor: text5,
        },
      },
      productsRoot: {
        background: background3,
      },
      productsButton: {
        boxShadow: 'none',
        background: primary,
        backgroundColor: primary,
        '&:hover': {
          boxShadow: 'none',
          background: text5,
          backgroundColor: text5,
        },
      },
    },
    affiliatesFooter: {
      footerRoot: {
        background: background3,
      },
      productsTerms: {
        '&:hover': {
          color: primary,
        },
      },
    },
    affiliateHeader: {
      loginBarRoot: {
        background: background3,
        '@media (pointer: coarse)': {
          background: background1,
        },
      },
      topMenuItemActive: {
        color: primary,
      },
      topMenuItem: {
        '&:hover': {
          color: primary,
        },
      },
      reasonsIcon: {
        fill: background3,
      },
      burgerOverlay: {
        background: background1,
      },
    },
    affiliatesCommission: {
      commissionTableCell: {
        background: white,
      },
    },
    affiliatesProducts: {
      productsButton: {
        boxShadow: 'none',
        background: primary,
        backgroundColor: primary,
        '&:hover': {
          boxShadow: 'none',
          background: text5,
          backgroundColor: text5,
        },
      },
    },
    affiliatesContacts: {
      contactsMail: {
        '&:hover': {
          color: text5,
        },
      },
    },
  };
};
