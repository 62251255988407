// @flow
import { type JSSThemeDefaultAccountSectionType } from '../JSSThemeDefaultAccountSection';

export const JSSThemeAccountSection61200: JSSThemeDefaultAccountSectionType = themeVars => {
  const {
    colors: {
      colorTypes: { background3, black },
    },
  } = themeVars;
  return {
    BetsHistory: {
      filterSelectionItem: {
        color: black,
      },
    },
    OPMPageComponent: {
      root: {
        '@media (max-width: 600px)': {
          flexWrap: 'nowrap',
          flexDirection: 'column',
          alignItems: 'center',
        },
      },
    },
    OPMProvider: {
      providerRoot: {
        background: background3,
        border: '1px solid #223553',
        boxShadow: 'none',
        maxWidth: 170,
        '@media (max-width: 600px)': {
          maxWidth: 230,
          width: 230,
          minHeight: 170,
        },
      },
      '@media (max-width: 370px)': {
        providerRoot: {
          width: 'calc(80% - 10px)',
          maxWidth: '100%',
        },
      },
      logoImg: {
        marginBottom: 10,
        opacity: '.9',
      },
    },
    InfoMessage: {
      messageComponent: {
        maxWidth: '100%',
        width: 'fit-content',
      },
    },
  };
};
