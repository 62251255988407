// @flow
import { type JSSThemeDefaultHeaderType } from '../JSSThemeDefaultHeader';

export const JSSThemeHeader61200: JSSThemeDefaultHeaderType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { background1 },
    },
  } = themeVars;
  return {
    HeaderDesktop: {
      root: {
        background: background1,
      },
      authContentWrap: {
        justifyContent: 'normal',
        padding: [margins.xs, 0, 0],
        '@media screen and (max-width: 1700px)': {
          marginRight: 10,
        },
      },
      extraUserElementsWrapper: {
        margin: [margins.xs, 0, margins.sm],
      },
    },
    LogoBrand: {
      logoWrapper: {
        width: 250,
        padding: '7px 25px 10px',
        '@media screen and (max-width: 1700px)': {
          padding: '7px 10px 10px',
        },
        '@media screen and (max-width: 1320px)': {
          width: 150,
          flexShrink: 0,
          marginRight: 10,
        },
        '@media screen and (max-width: 1080px)': {
          width: 120,
        },
      },
      logo: {
        maxWidth: 200,
        '@media screen and (max-width: 1320px)': {
          maxWidth: 150,
        },
      },
    },
  };
};
