// @flow
import { type JSSThemeDefaultMyBetsType } from '../JSSThemeDefaultMyBets';

export const JSSThemeMyBets61200: JSSThemeDefaultMyBetsType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { whiteBg1, text2Bg3 },
      colorTypes: { white, black, background1 },
    },
  } = themeVars;

  return {
    ConfirmationBox: {
      text: {
        color: white,
        fontWeight: 'normal',
      },
      confirmation: {
        background: background1,
      },
      loading: {
        color: '#fff',
        background: '#3E3E3E',
      }
    },
    CashOut: {
      success: {
        color: '#fff',
        background: '#3E3E3E',
      }
    },
    MyBets: {
      content: {
        ...whiteBg1,
      },
      myBetsTabsItem: {
        ...text2Bg3,
        '&:hover, &.active': {
          color: black,
          background: '#52EF52',
        },
      },
    },
  };
};
